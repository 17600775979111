@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

:root {
  --primary-color: #3386ff;
  --separator-line-color: #cacaca;
  --light-color: #ffffff;
  --dark-color: #151616;
  --dark-gray: #858585;
  --light-gray: #fff;
  --light-black: #363636;
  --dark-white: #dddddd;
  --transp-primary-light: #92beff52;
  --transp-primary-dark: #92beff18;
  --scroller-default-color: #c7c7c7;
  --scroller-hover-color: #888888;
  --btn-shadow-light: #999;
  --btn-shadow-dark: #666;
  --error-color: #ff4444;
  --success-color: #28a745;
  --red-color: red;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ General ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  color: var(--dark-color);
  background-color: var(--light-color);
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0.8vw;
  height: 0.8vh;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scroller-default-color);
  border: 0.2vw solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroller-hover-color);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: transparent;
  background-clip: padding-box;
}

a {
  text-decoration: none !important;
  -webkit-user-drag: none !important;
  color: var(--dark-color);
}

p {
  font-weight: 400;
}

img {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.reactIcon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1px;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -webkit-user-select: none; /* Safari and Chrome */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ Dark Mode ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.darkTheme {
  --light-color: #151616;
  --dark-color: #ffffff;
  --dark-gray: #cecece;
  --light-gray: #858585;
  --light-black: #d4d4d4;
  --dark-white: #363636;
  --transp-primary-light: #92beff18;
  --transp-primary-dark: #92beff52;
  --scroller-default-color: #555555;
  --scroller-hover-color: #a8a8a8;
  --btn-shadow-dark: #999;
  --btn-shadow-light: #666;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ loader ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: var(--light-color);
}

#loader::before {
  content: "";
  position: fixed;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  border: 4px solid var(--primary-color);
  border-top-color: var(--light-color);
  border-bottom-color: var(--light-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: animate-loader 1s linear infinite;
  animation: animate-loader 1s linear infinite;
}

@-webkit-keyframes animate-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ Tooltip ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.tooltipTheme {
  background-color: var(--light-black) !important;
  color: var(--light-color) !important;
}

.tooltipTheme.place-top::after {
  border-top-color: var(--light-black) !important;
}

.tooltipTheme.place-right::after {
  border-right-color: var(--light-black) !important;
}

.tooltipTheme.place-bottom::after {
  border-bottom-color: var(--light-black) !important;
}

.tooltipTheme.place-left::after {
  border-left-color: var(--light-black) !important;
}

.type-error {
  background-color: var(--error-color) !important;
  color: white !important;
}

.type-error.place-top::after {
  border-top-color: var(--error-color) !important;
}

.type-error.place-right::after {
  border-right-color: var(--error-color) !important;
}

.type-error.place-bottom::after {
  border-bottom-color: var(--error-color) !important;
}

.type-error.place-left::after {
  border-left-color: var(--error-color) !important;
}

.type-success {
  background-color: var(--success-color) !important;
  color: white !important;
}

.type-success.place-top::after {
  border-top-color: var(--success-color) !important;
}

.type-success.place-right::after {
  border-right-color: var(--success-color) !important;
}

.type-success.place-bottom::after {
  border-bottom-color: var(--success-color) !important;
}

.type-success.place-left::after {
  border-left-color: var(--success-color) !important;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ Header ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

header .navbar-container {
  width: 94%;
  margin: auto;
  border-bottom: 4px solid var(--separator-line-color);
  overflow: hidden;
}

header .navbar-container .navbar {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100px;
}

header .navbar-container .navbar a.logo-container {
  margin-inline: 30px;
}

header .navbar-container .navbar a .myLogo {
  width: 60px;
  cursor: pointer;
  vertical-align: middle;
}

header .navbar-container .navbar .reactIcon.menu-icon {
  font-size: 27px;
  padding: 0;
  cursor: pointer;
  margin-right: 22px;
  display: none;
  color: var(--dark-color);
  pointer-events: none;
}

@media (hover: none) {
  header .navbar-container .navbar .reactIcon.menu-icon:active {
    color: var(--primary-color);
  }
}

@media (hover: hover) {
  header .navbar-container .navbar .reactIcon.menu-icon:hover {
    color: var(--primary-color);
  }

  header .navbar-container .navbar .reactIcon.menu-icon:active {
    color: var(--dark-white);
  }
}

header .navbar-container .navbar nav {
  flex: 1;
  text-align: right;
}

header .navbar-container .navbar nav ul {
  margin-top: -12px;
  max-height: 0px;
}

header .navbar-container .navbar nav ul li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
}

header .navbar-container .navbar nav ul li a {
  text-transform: uppercase;
}

header .navbar-container .navbar nav ul li .active {
  background-color: var(--primary-color);
  border-radius: 20px;
  padding: 7px 25px;
  color: white;
}

@media (hover: none) {
  header .navbar-container .navbar nav ul li .inactive:active {
    color: var(--primary-color);
  }
}

@media (hover: hover) {
  header .navbar-container .navbar nav ul li .inactive:hover {
    color: var(--primary-color);
  }
}

header .navbar-container .navbar .theme {
  cursor: pointer;
  font-size: 30px;
  color: var(--dark-color);
  margin-right: 30px;
}

header .navbar-container .navbar .reactIcon.theme {
  cursor: pointer;
  font-size: 25px;
  color: var(--dark-color);
}

@media (hover: none) {
  header .navbar-container .navbar .theme:active {
    color: var(--primary-color);
  }
}

@media (hover: hover) {
  header .navbar-container .navbar .theme:hover {
    color: var(--primary-color);
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ Scroll Up ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.goTopBtn {
  position: fixed;
  bottom: 40px;
  right: 2.5%;
  width: 40px;
  height: 40px;
  background-color: var(--light-black);
  color: var(--light-color);
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  z-index: 50;
}

.goTopBtn .reactIcon {
  font-size: 25px;
  margin-top: -1.5px;
}

@media (hover: none) {
  .goTopBtn:active {
    background-color: var(--primary-color);
  }
}

@media (hover: hover) {
  .goTopBtn:hover {
    background-color: var(--primary-color);
  }

  .goTopBtn:active {
    background-color: #0066ff;
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ Footer ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

footer .copyright-container {
  width: 94%;
  margin: auto;
  border-top: 4px solid var(--separator-line-color);
  padding: 20px 0;
}

footer .copyright-container p {
  font-size: 15px;
  color: var(--dark-color);
  line-height: 18px;
  text-align: center;
}

footer .copyright-container p .reactIcon {
  margin-right: 4px;
  font-size: 16px;
  margin-top: -4px;
}

footer .copyright-container p .myUsername {
  font-weight: bold;
  opacity: 0.9;
  position: relative;
  cursor: pointer;
}

footer .copyright-container p .myUsername::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--dark-color);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

@media (hover: none) {
  footer .copyright-container p .myUsername:active {
    opacity: 1;
  }

  footer .copyright-container p .myUsername:active::before {
    transform: scaleX(1);
  }
}

@media (hover: hover) {
  footer .copyright-container p .myUsername:hover {
    opacity: 1;
  }

  footer .copyright-container p .myUsername:hover::before {
    transform: scaleX(1);
  }
}

footer .copyright-container p .heart {
  animation: heartbeat 1s infinite;
  margin-left: 7px;
  font-size: 19px;
  color: var(--red-color);
}

@-webkit-keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 800px) {
  header .navbar-container .navbar {
    height: 80px;
  }

  header .navbar-container .navbar .theme {
    font-size: 27px;
    margin-right: 10px;
  }

  header .navbar-container .navbar a.logo-container {
    margin-inline: 10px;
  }

  header .navbar-container .navbar a .myLogo {
    width: 50px;
  }

  header .navbar-container .navbar .reactIcon.menu-icon {
    display: block;
    pointer-events: auto;
  }

  header .navbar-container .navbar nav #menu-list {
    overflow: hidden;
    transition: 0.5s;
  }

  header .navbar-container .navbar nav ul {
    width: 94%;
    background: linear-gradient(
      to bottom,
      var(--separator-line-color),
      rgba(221, 221, 221, 0.938)
    );
    position: absolute;
    top: 92px;
    right: 3%;
    z-index: 10;
  }

  header .navbar-container .navbar nav ul li {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  header .navbar-container .navbar nav ul li .active {
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    color: var(--primary-color);
    font-weight: bold;
  }

  header .navbar-container .navbar nav ul li .inactive {
    color: #151616;
  }

  .goTopBtn {
    bottom: 32px;
    right: 4%;
    width: 35px;
    height: 35px;
    line-height: 33.5px;
  }

  .goTopBtn .reactIcon {
    font-size: 22px;
    margin-top: -2px;
  }

  footer .copyright-container {
    padding: 15px 0;
  }

  footer .copyright-container p {
    font-size: 13px;
  }

  footer .copyright-container p .reactIcon {
    font-size: 14px;
    margin-top: -3px;
  }
}

@media only screen and (max-width: 500px) {
  footer .copyright-container p {
    font-size: 11px;
  }

  footer .copyright-container p .reactIcon {
    font-size: 12px;
  }

  footer .copyright-container p .heart {
    margin-left: 5px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 300px) {
  footer .copyright-container p {
    font-size: 10px;
  }

  footer .copyright-container p .reactIcon {
    font-size: 11px;
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

section {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.showcase-content .title h2 {
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 1500px) {
  .showcase-content .title h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1000px) {
  section {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .showcase-content .title h2 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 500px) {
  .showcase-content .title h2 {
    font-size: 19px;
  }
}
