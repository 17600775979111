/* %%%%%%%%%%%%%%%%%%%%%%%%%%[ Contact Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.contact .showcase-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact .showcase-content .infos-container {
  width: 40%;
}

.contact .showcase-content .infos-container h1 {
  font-size: 45px;
  letter-spacing: 2px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
}

.contact .showcase-content .infos-container .description {
  font-size: 25px;
  font-weight: 400;
  opacity: 0.7;
}

.contact .showcase-content .infos-container .my-link .directly {
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.contact .showcase-content .infos-container .my-link .directly p {
  font-weight: 600;
  padding-left: 50px;
  line-height: 21px;
  padding-top: 5px;
  position: relative;
  margin: 3px 0;
  font-size: 19px;
  display: inline-block;
  user-select: text;
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .email
  .reactIcon:not(.reactIcon.copy),
.contact .showcase-content .infos-container .my-link .directly .call .reactIcon,
.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .location
  .reactIcon {
  position: absolute;
  left: 0px;
  top: 7px;
  background-color: var(--primary-color);
  border-radius: 25%;
  color: var(--light-color);
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .email
  .reactIcon:not(.reactIcon.copy) {
  padding: 5px 7px;
  font-size: 28px;
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .call
  .reactIcon {
  padding: 3px 5px;
  font-size: 32px;
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .location
  .reactIcon {
  padding: 4px 6px;
  font-size: 30px;
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .email
  span:not(.reactIcon),
.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .call
  span:not(.reactIcon),
.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .location
  span:not(.reactIcon) {
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 500;
  display: block;
}

.contact .showcase-content .infos-container .my-link .directly .email .copy {
  margin-left: 7px;
  margin-top: -2px;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.2s;
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .email
  .copy:hover {
  -ms-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  .email
  .copy:active {
  color: var(--primary-color);
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .directly
  p:hover
  > .reactIcon:not(.reactIcon.copy) {
  color: var(--primary-color);
  background-color: transparent;
  transition: 0.3s;
}

.contact .showcase-content .infos-container .my-link .socialNetworks-container {
  display: table;
  margin-bottom: 10px;
  margin: auto;
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .socialNetworks-container
  .social-business {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  /* margin-bottom: 7px; */
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .socialNetworks-container
  .social-media {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .socialNetworks-container
  a {
  transition: all ease-in-out 150ms;
  display: flex;
  padding: 5px;
}

@media (hover: none) {
  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a:active {
    margin-top: -10px;
    transform: translate(0, -8);
  }
}

@media (hover: hover) {
  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a:hover {
    margin-top: -10px;
    transform: translate(0, -8);
  }
}

.contact
  .showcase-content
  .infos-container
  .my-link
  .socialNetworks-container
  a
  img {
  height: 45px;
  margin: auto;
}

.contact .showcase-content .chat-container {
  position: relative;
  flex-basis: 60%;
  display: flex;
  align-items: center;
  margin-left: 30px;
  text-align: center;
}

.contact .showcase-content .chat-container form .form-alert .output {
  display: block;
  display: none;
}

.contact .showcase-content .chat-container form input {
  background-color: var(--dark-white);
  border-radius: 20px;
  color: var(--dark-color);
  outline: none;
  width: 31%;
  height: 45px;
  padding: 0px 20px;
  border: 2px solid transparent;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins";
}

.contact .showcase-content .chat-container form input[type="email"] {
  margin-inline: 2.5%;
}

.contact .showcase-content .chat-container form textarea {
  background-color: var(--dark-white);
  border-radius: 20px;
  color: var(--dark-color);
  outline: none;
  height: 300px;
  padding: 10px 20px;
  margin-top: 2.5%;
  margin-bottom: 2%;
  border: 2px solid transparent;
  width: 99%;
  resize: none;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins";
}

.contact
  .showcase-content
  .chat-container
  form
  input::-webkit-input-placeholder,
.contact
  .showcase-content
  .chat-container
  form
  textarea::-webkit-input-placeholder {
  font-size: 17px;
  font-weight: 400;
}

@media (hover: hover) {
  .contact .showcase-content .chat-container form input:hover,
  .contact .showcase-content .chat-container form textarea:hover {
    filter: drop-shadow(0px 0px 2px var(--dark-color));
  }
}

.contact .showcase-content .chat-container form input:focus,
.contact .showcase-content .chat-container form textarea:focus {
  border: 2px solid var(--primary-color);
  transition: 0.3s;
  filter: drop-shadow(0px 0px 0px var(--dark-color));
}

.contact .showcase-content .chat-container form .submit-btn {
  padding: 10px 15px;
  font-size: 18px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--primary-color);
  overflow: hidden;
  position: relative;
  transition: 0.2s;
  font-weight: 600;
  letter-spacing: 2px;
}

.contact .showcase-content .chat-container form .submit-btn .reactIcon {
  margin-left: 10px;
  font-size: 19px;
  margin-top: -1px;
}

.contact .showcase-content .chat-container form .submit-btn::before {
  content: "";
  height: 3px;
  width: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  background: var(--primary-color);
  transition: 0.4s;
}

@media (hover: none) {
  .contact .showcase-content .chat-container form .submit-btn::before {
    transition: 0.2s;
  }

  .contact .showcase-content .chat-container form .submit-btn:active::before {
    width: 100%;
  }
}

@media (hover: hover) {
  .contact .showcase-content .chat-container form .submit-btn:hover::before {
    width: 100%;
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 1500px) {
  .contact .showcase-content .infos-container {
    width: 43%;
  }

  .contact .showcase-content .infos-container h1 {
    font-size: 43px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1380px) {
  .contact .showcase-content .infos-container {
    width: 45%;
  }

  .contact .showcase-content .infos-container h1 {
    font-size: 40px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1200px) {
  .contact .showcase-content .infos-container {
    width: 48%;
  }

  .contact .showcase-content .infos-container h1 {
    font-size: 38px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1150px) {
  .contact .showcase-content .chat-container form input {
    font-size: 14px;
  }

  .contact .showcase-content .chat-container form textarea {
    font-size: 14px;
  }

  .contact
    .showcase-content
    .chat-container
    form
    input::-webkit-input-placeholder,
  .contact
    .showcase-content
    .chat-container
    form
    textarea::-webkit-input-placeholder {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1075px) {
  .contact .showcase-content .infos-container {
    width: 50%;
  }

  .contact .showcase-content .infos-container h1 {
    font-size: 36px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 18px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a {
    padding: 4px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a
    img {
    height: 40px;
  }

  .contact .showcase-content .chat-container {
    margin-left: 15px;
  }

  .contact .showcase-content .chat-container form input {
    padding: 0px 15px;
  }

  .contact .showcase-content .chat-container form textarea {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .contact .showcase-content .infos-container {
    width: 48%;
  }

  .contact .showcase-content .infos-container h1 {
    font-size: 33px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 16px;
  }

  .contact .showcase-content .infos-container .my-link .directly {
    margin-bottom: 23px;
    margin-left: 10px;
  }

  .contact .showcase-content .infos-container .my-link .directly p {
    padding-left: 50px;
    line-height: 20px;
    padding-top: 3px;
    margin: 0;
    font-size: 15px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .email
    .reactIcon:not(.reactIcon.copy),
  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .call
    .reactIcon,
  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .location
    .reactIcon {
    top: 5px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .email
    .reactIcon:not(.reactIcon.copy) {
    padding: 5px 7px;
    font-size: 25px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .call
    .reactIcon {
    padding: 3px 5px;
    font-size: 29px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .location
    .reactIcon {
    padding: 4px 6px;
    font-size: 27px;
  }

  .contact .showcase-content .infos-container .my-link .directly .email .copy {
    margin-left: 6px;
    margin-top: -2px;
    font-size: 16px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a {
    padding: 3px;
  }

  /* .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    .social-business {
    margin-bottom: 6px;
  } */

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a
    img {
    height: 36px;
  }

  .contact .showcase-content .chat-container form input {
    width: 32%;
    font-size: 12px;
  }

  .contact .showcase-content .chat-container form input[type="email"] {
    margin-inline: 0.75%;
  }

  .contact .showcase-content .chat-container form textarea {
    font-size: 12px;
    height: 270px;
    margin-top: 2%;
  }

  .contact
    .showcase-content
    .chat-container
    form
    input::-webkit-input-placeholder,
  .contact
    .showcase-content
    .chat-container
    form
    textarea::-webkit-input-placeholder {
    font-size: 12px;
  }
}

@media only screen and (max-width: 800px) {
  .contact .showcase-content {
    flex-direction: column;
  }

  .contact .showcase-content .infos-container {
    width: 100%;
  }

  .contact .showcase-content .infos-container h1 {
    font-size: 40px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 20px;
  }

  .contact .showcase-content .infos-container .my-link .directly {
    margin-left: 15px;
    margin-bottom: 27px;
  }

  .contact .showcase-content .infos-container .my-link .directly p {
    padding-left: 45px;
    line-height: 20px;
    padding-top: 2px;
    margin: 2px 0;
    font-size: 16px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a {
    padding: 4px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a
    img {
    height: 39px;
  }

  .contact .showcase-content .chat-container {
    margin-left: 0px;
    margin-top: 25px;
  }

  .contact .showcase-content .chat-container form input {
    font-size: 14px;
    width: 100%;
    height: 45px;
  }

  .contact .showcase-content .chat-container form input[type="email"] {
    margin: 2% 0;
  }

  .contact .showcase-content .chat-container form textarea {
    font-size: 14px;
    height: 200px;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
  }

  .contact
    .showcase-content
    .chat-container
    form
    input::-webkit-input-placeholder,
  .contact
    .showcase-content
    .chat-container
    form
    textarea::-webkit-input-placeholder {
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .contact .showcase-content .infos-container h1 {
    font-size: 34px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 17px;
  }

  .contact .showcase-content .infos-container .my-link .directly p {
    padding-left: 45px;
    line-height: 18px;
    padding-top: 5px;
    margin: 1px 0;
    font-size: 14px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a {
    padding: 3px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a
    img {
    height: 35px;
  }

  .contact .showcase-content .chat-container form .submit-btn {
    font-size: 16px;
    letter-spacing: 1.5px;
  }

  .contact .showcase-content .chat-container form .submit-btn .reactIcon {
    margin-left: 8px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 350px) {
  .contact .showcase-content .infos-container h1 {
    font-size: 28px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 14px;
  }

  .contact .showcase-content .infos-container .my-link .directly {
    margin-bottom: 23px;
  }

  .contact .showcase-content .infos-container .my-link .directly p {
    padding-left: 40px;
    line-height: 15px;
    padding-top: 5px;
    margin: 1px 0;
    font-size: 12px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .email
    .reactIcon:not(.reactIcon.copy),
  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .call
    .reactIcon,
  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .location
    .reactIcon {
    top: 4.5px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .email
    .reactIcon:not(.reactIcon.copy) {
    padding: 4px 6px;
    font-size: 22px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .call
    .reactIcon {
    padding: 2px 4.2px;
    font-size: 25px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .location
    .reactIcon {
    padding: 3px 5.1px;
    font-size: 23px;
  }

  .contact .showcase-content .infos-container .my-link .directly .email .copy {
    margin-left: 5px;
    margin-top: -2px;
    font-size: 14px;
  }

  /* .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    .social-business {
    margin-bottom: 5px;
  } */

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a {
    padding: 3px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a
    img {
    height: 30px;
  }

  .contact .showcase-content .chat-container form input {
    font-size: 13px;
  }

  .contact .showcase-content .chat-container form textarea {
    font-size: 13px;
  }

  .contact
    .showcase-content
    .chat-container
    form
    input::-webkit-input-placeholder,
  .contact
    .showcase-content
    .chat-container
    form
    textarea::-webkit-input-placeholder {
    font-size: 14px;
  }
}

@media only screen and (max-width: 300px) {
  .contact .showcase-content .infos-container h1 {
    font-size: 23px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 12.5px;
  }

  .contact .showcase-content .infos-container .my-link .directly {
    margin-left: 10px;
    margin-top: 16px;
  }

  .contact .showcase-content .infos-container .my-link .directly p {
    padding-left: 34px;
    line-height: 12px;
    padding-top: 7px;
    margin: -3px 0;
    margin-left: -6px;
    font-size: 10.5px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .email
    .reactIcon:not(.reactIcon.copy),
  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .call
    .reactIcon,
  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .location
    .reactIcon {
    top: 4.5px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .email
    .reactIcon:not(.reactIcon.copy) {
    padding: 3px 5.4px;
    font-size: 19.2px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .call
    .reactIcon {
    padding: 1.5px 4.2px;
    font-size: 22px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .location
    .reactIcon {
    padding: 2px 5.1px;
    font-size: 20px;
  }

  .contact .showcase-content .infos-container .my-link .directly .email .copy {
    margin-left: 4px;
    margin-top: -2px;
    font-size: 12px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a {
    padding: 2px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a
    img {
    height: 28px;
  }

  .contact .showcase-content .chat-container form input {
    font-size: 12px;
    height: 38px;
  }

  .contact .showcase-content .chat-container form textarea {
    font-size: 12px;
  }

  .contact
    .showcase-content
    .chat-container
    form
    input::-webkit-input-placeholder,
  .contact
    .showcase-content
    .chat-container
    form
    textarea::-webkit-input-placeholder {
    font-size: 13px;
  }
}

@media only screen and (max-width: 250px) {
  .contact .showcase-content .infos-container h1 {
    font-size: 20px;
  }

  .contact .showcase-content .infos-container .description {
    font-size: 11.5px;
  }

  .contact .showcase-content .infos-container .my-link .directly {
    margin-left: 7px;
  }

  .contact .showcase-content .infos-container .my-link .directly p {
    font-size: 9.5px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .directly
    .email
    .copy-icon {
    font-size: 13px;
  }

  .contact
    .showcase-content
    .infos-container
    .my-link
    .socialNetworks-container
    a
    img {
    height: 24px;
  }

  .contact .showcase-content .chat-container form input {
    font-size: 11px;
  }

  .contact .showcase-content .chat-container form textarea {
    font-size: 11px;
  }

  .contact
    .showcase-content
    .chat-container
    form
    input::-webkit-input-placeholder,
  .contact
    .showcase-content
    .chat-container
    form
    textarea::-webkit-input-placeholder {
    font-size: 12px;
  }
}
