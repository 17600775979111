/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Separator ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

hr.separator {
  border-top: 1px solid var(--dark-white);
  margin: 30px auto 45px;
  max-width: 40%;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 800px) {
  hr.separator {
    margin: 20px auto 30px;
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Infos Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.about .showcase-content .infos {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.about .showcase-content .infos .personal {
  position: relative;
  width: 100%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

.about .showcase-content .infos .personal .container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.about .showcase-content .infos .personal .container .title {
  position: relative;
  width: 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.about .showcase-content .infos .personal .container .to-ask {
  position: relative;
  width: 100%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask.first
  .multiple
  li:first-child {
  margin-bottom: 15px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask.second
  .multiple
  li:first-child {
  margin-bottom: 15px;
}

.about .showcase-content .infos .personal .container .to-ask.third {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 15px;
}

.about .showcase-content .infos .personal .container .to-ask .multiple {
  list-style: none;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .question {
  opacity: 0.8;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 1px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .response {
  font-weight: 500;
  display: inline-block;
  font-size: 17px;
  letter-spacing: 1px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask.first
  .multiple
  li:hover
  > .response,
.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask.second
  .multiple
  li:hover
  > .response {
  color: var(--primary-color);
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages {
  padding: 10px 20px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar {
  margin: 10px 0;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar:first-child {
  margin-top: 0px;
}

/* .about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar:hover
  .progress-line
  span::before,
.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar:hover
  .progress-line
  span::after {
  display: block;
} */

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar
  .lang {
  margin-bottom: 5px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar
  .lang
  span {
  font-size: 17px;
  font-weight: 500;
  opacity: 0;
  animation: showText1 0.5s 1s linear forwards;
  letter-spacing: 1px;
}

@-webkit-keyframes showText1 {
  100% {
    opacity: 1;
  }
}

@keyframes showText1 {
  100% {
    opacity: 1;
  }
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar
  .lang
  .level {
  float: right;
  display: none;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar
  .progress-line {
  position: relative;
  height: 10px;
  width: 100%;
  background-color: var(--dark-white);
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate-line 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar
  .progress-line
  span {
  height: 100%;
  background-color: var(--primary-color);
  position: absolute;
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate-line 1s 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}

@-webkit-keyframes animate-line {
  100% {
    transform: scaleX(1);
  }
}

@keyframes animate-line {
  100% {
    transform: scaleX(1);
  }
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar.arabic
  .progress-line
  span {
  width: 90%;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar.french
  .progress-line
  span {
  width: 70%;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar.english
  .progress-line
  span {
  width: 60%;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar
  .progress-line
  span::before {
  position: absolute;
  content: "";
  right: 0;
  top: -10px;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-top-color: var(--light-black);
  opacity: 0;
  animation: showText2 0.3s linear forwards;
  /* display: none; */
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar
  .progress-line
  span::after {
  position: absolute;
  right: 0;
  top: -28px;
  background-color: var(--light-black);
  color: var(--light-color);
  font-size: 12px;
  font-weight: 500;
  padding: 1px 3px;
  border-radius: 3px;
  opacity: 0;
  /* animation: showText2 0.5s 1.5s linear forwards; */
  animation: showText2 0.3s linear forwards;
  /* display: none; */
}

@-webkit-keyframes showText2 {
  100% {
    opacity: 1;
  }
}

@keyframes showText2 {
  100% {
    opacity: 1;
  }
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar.arabic
  .progress-line
  span::after {
  content: "90%";
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar.french
  .progress-line
  span::after {
  content: "70%";
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .languages
  .bar.english
  .progress-line
  span::after {
  content: "60%";
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask.third
  .multiple
  li:first-child {
  margin-bottom: 8px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .freelance-links {
  position: relative;
  width: 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 15px;
  padding-inline: 30px;
  text-align: center;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .freelance-links
  .freelance-btn {
  background-color: var(--primary-color);
  text-align: center;
  display: inline-block;
  position: relative;
  color: white;
  padding: 10px 0px;
  width: 150px;
  border-radius: 26px;
  overflow: hidden;
  transition: all 0.2s linear 0s;
  font-size: 16px;
  letter-spacing: 1px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .freelance-links
  .freelance-btn:first-child {
  margin-right: 10px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .freelance-links
  i {
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0 50% 50% 0;
  transform: scale(0, 1);
  transform-origin: left center;
  transition: all 0.2s linear 0s;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .freelance-links
  .reactIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0 50% 50% 0;
  transform: scale(0, 1);
  transform-origin: left center;
  transition: all 0.2s linear 0s;
  font-size: 19px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .freelance-links
  .freelance-btn:hover {
  text-indent: 30px;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .freelance-links
  .freelance-btn:hover
  i {
  transform: scale(1, 1);
  text-indent: 0;
}

.about
  .showcase-content
  .infos
  .personal
  .container
  .to-ask
  .multiple
  li
  .freelance-links
  .freelance-btn:hover
  .reactIcon {
  transform: scale(1, 1);
  text-indent: 0;
}

.about .showcase-content .infos .results {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}

.about .showcase-content .infos .results .container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  height: 100%;
}

.about .showcase-content .infos .results .container .indicator {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
  margin: auto;
  margin-bottom: 10px;
}

.about .showcase-content .infos .results .container .indicator .box-stats {
  padding: 20px 30px 25px 40px;
  border-radius: 5px;
  border: 2px solid var(--dark-white);
}

.about .showcase-content .infos .results .container .indicator .box-stats h3 {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  color: var(--primary-color);
}

.about
  .showcase-content
  .infos
  .results
  .container
  .indicator
  .box-stats
  h3::after {
  content: "+";
  position: absolute;
  right: -24px;
  font-size: 33px;
  font-weight: 300;
  top: 2px;
}

.about .showcase-content .infos .results .container .indicator .box-stats p {
  padding-left: 45px;
  margin: 0;
  position: relative;
  text-transform: uppercase;
}

.about
  .showcase-content
  .infos
  .results
  .container
  .indicator
  .box-stats
  p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  width: 30px;
  height: 2px;
  background: var(--dark-white);
}

.about .showcase-content .infos .results .container .indicator p span {
  display: block;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 1450px) {
  .about .showcase-content .infos .personal {
    -ms-flex: 0 0 72%;
    flex: 0 0 72%;
    max-width: 72%;
  }

  .about .showcase-content .infos .results {
    -ms-flex: 0 0 28%;
    flex: 0 0 28%;
    max-width: 28%;
  }

  .about .showcase-content .infos .results .container .indicator {
    -ms-flex: 0 0 95%;
    flex: 0 0 95%;
    max-width: 95%;
  }
}

@media only screen and (max-width: 1200px) {
  .about .showcase-content .infos .results .container .indicator {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1100px) {
  .about .showcase-content .infos .results .container .indicator .box-stats {
    padding: 20px 30px 25px 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .about .showcase-content .infos .personal {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .about .showcase-content .infos .results {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 30px;
  }

  .about .showcase-content .infos .results .container .indicator {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .about .showcase-content .infos .results .container .indicator .box-stats {
    padding: 20px 30px 25px 40px;
  }
}

@media only screen and (max-width: 800px) {
  .about .showcase-content .infos .personal .container .title {
    margin-bottom: 15px;
  }

  /* .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar:hover
    .progress-line
    span::before,
  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar:hover
    .progress-line
    span::after {
    display: none;
  } */

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar
    .progress-line
    span::before,
  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar
    .progress-line
    span::after {
    display: none;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar
    .lang
    .level {
    display: block;
  }

  /* .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar
    .progress-line
    span::before {
    display: none;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar
    .progress-line
    span::after {
    display: none;
  } */

  .about .showcase-content .infos .results .container .indicator .box-stats {
    padding: 20px 20px 25px 20px;
  }
}

@media only screen and (max-width: 650px) {
  .about .showcase-content .infos .results {
    margin-top: 20px;
  }

  .about .showcase-content .infos .results .container .indicator {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }
}

@media only screen and (max-width: 542px) {
  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .freelance-links {
    padding-inline: 0px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .freelance-links
    .freelance-btn:first-child {
    margin-right: 0px;
  }

  .about .showcase-content .infos .results .container .indicator {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (max-width: 435px) {
  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .question {
    font-size: 14px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .response {
    font-size: 14px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages {
    padding-inline: 10px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar
    .lang
    span {
    font-size: 14px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .freelance-links
    .freelance-btn {
    font-size: 13px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .freelance-links
    .reactIcon {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .response {
    display: block;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .response.dispo {
    display: inline;
  }

  .about .showcase-content .infos .results .container .indicator .box-stats {
    padding: 5px 5px 10px 10px;
  }

  .about .showcase-content .infos .results .container .indicator .box-stats p {
    font-size: 13px;
    margin-top: -10px;
  }

  .about
    .showcase-content
    .infos
    .results
    .container
    .indicator
    .box-stats
    p::before {
    top: 8px;
    width: 30px;
  }
}

@media only screen and (max-width: 360px) {
  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .question {
    font-size: 12px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .response {
    font-size: 12px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar
    .lang
    span {
    font-size: 12px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .freelance-links
    .freelance-btn {
    font-size: 12px;
  }
}

@media only screen and (max-width: 324px) {
  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .question {
    font-size: 11px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .response {
    font-size: 11px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .languages
    .bar
    .lang
    span {
    font-size: 11px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .freelance-links
    .freelance-btn {
    font-size: 11px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .freelance-links
    .reactIcon {
    font-size: 13px;
  }

  .about .showcase-content .infos .results .container .indicator .box-stats {
    padding: 0px 5px 5px 10px;
  }
}

@media only screen and (max-width: 320px) {
  .about .showcase-content .infos .results .container .indicator .box-stats h3 {
    font-size: 38px;
  }

  .about
    .showcase-content
    .infos
    .results
    .container
    .indicator
    .box-stats
    h3::after {
    font-size: 33px;
    top: -2px;
  }

  .about .showcase-content .infos .results .container .indicator .box-stats p {
    padding-left: 35px;
    font-size: 11px;
  }

  .about
    .showcase-content
    .infos
    .results
    .container
    .indicator
    .box-stats
    p::before {
    top: 8px;
    width: 24px;
  }
}

@media only screen and (max-width: 280px) {
  .about .showcase-content .infos .results .container .indicator .box-stats p {
    font-size: 10px;
  }

  .about
    .showcase-content
    .infos
    .personal
    .container
    .to-ask
    .multiple
    li
    .freelance-links
    .freelance-btn {
    width: 130px;
  }

  .about .showcase-content .infos .results .container .indicator {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Skills Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.about .showcase-content .skills .title {
  margin-bottom: 20px;
  text-align: center;
}

.about .showcase-content .skills .menu {
  margin: auto;
  display: block;
  clear: both;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
}

.about .showcase-content .skills .menu ul li {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 10px;
  text-underline-position: under;
}

.about .showcase-content .skills .menu ul li > span {
  color: var(--primary-color);
  padding: 3px 5px;
}

.about .showcase-content .skills .menu ul li > span.active {
  text-decoration: underline !important;
}

.about .showcase-content .skills .menu ul li > span .reactIcon {
  font-size: 18px;
  margin-left: 4px;
  margin-top: -1px;
}

@media (hover: hover) {
  .about .showcase-content .skills .menu ul li > span:hover {
    background-color: var(--transp-primary-light);
  }
}

.about .showcase-content .skills .menu ul li > span:active {
  color: var(--dark-white);
}

.about .showcase-content .skills .container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 1rem;
  padding: 1rem 0;
}

.about .showcase-content .skills .container .box .skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.about .showcase-content .skills .container .box .skill .logo {
  width: 90px;
  height: 90px;
  position: relative;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.about .showcase-content .skills .container .box .skill .logo img {
  width: 80px;
  z-index: 2;
}

.about .showcase-content .skills .container .box .skill h4 {
  font-weight: 600;
  font-size: 16px;
}

@media (hover: none) {
  .about .showcase-content .skills .container .box:active .logo {
    transform: translateX(-20px);
  }
}

@media (hover: hover) {
  .about .showcase-content .skills .container .box:hover .logo {
    transform: translateX(-20px);
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 1750px) {
  .about .showcase-content .skills .container {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media only screen and (max-width: 1500px) {
  .about .showcase-content .skills .menu ul li {
    font-size: 14px;
    margin: 5px 8px;
  }

  .about .showcase-content .skills .container {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (max-width: 1250px) {
  .about .showcase-content .skills .container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 1000px) {
  .about .showcase-content .skills .menu ul li {
    font-size: 14px;
    margin: 5px 7px;
  }

  .about .showcase-content .skills .container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .about .showcase-content .skills .title {
    margin-bottom: 15px;
  }

  .about .showcase-content .skills .container .box .skill .logo img {
    width: 60px;
  }

  .about .showcase-content .skills .container .box .skill h4 {
    font-size: 13px;
  }

  .about .showcase-content .skills .menu ul li {
    font-size: 14px;
    margin: 5px 5px;
  }

  .about .showcase-content .skills .menu ul li > span {
    padding: 3px 3px;
  }

  .about .showcase-content .skills .container .box .skill {
    padding: 0.5rem;
    margin-bottom: 0rem;
  }
}

@media only screen and (max-width: 600px) {
  .about .showcase-content .skills .menu ul li {
    font-size: 12px;
    margin: 3px 4px;
  }

  .about .showcase-content .skills .menu ul li > span {
    padding: 3px 2px;
  }

  .about .showcase-content .skills .menu ul li > span .reactIcon {
    font-size: 16px;
    margin-left: 3px;
  }

  .about .showcase-content .skills .container {
    grid-template-columns: repeat(3, 1fr);
  }

  .about .showcase-content .skills .container .box .skill {
    padding: 0.3rem;
  }
}

@media only screen and (max-width: 400px) {
  .about .showcase-content .skills .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .about .showcase-content .skills .container .box .skill {
    padding: 0.1rem;
  }
}

@media only screen and (max-width: 250px) {
  .about .showcase-content .skills .container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Resume Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.about .showcase-content .resume {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.about .showcase-content .resume .title {
  position: relative;
  width: 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 40px;
  display: block;
  text-align: center;
}

.about .showcase-content .resume .container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.about .showcase-content .resume .container ul li {
  position: relative;
  padding: 0 20px 0 60px;
  margin: 0 0 50px;
}

.about .showcase-content .resume .container ul li::after {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  border-left: 1px solid var(--light-black);
}

.about .showcase-content .resume .sub-1 {
  margin-bottom: 30px;
  display: none;
}

hr.sub-separator {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  border-top: 1px solid var(--dark-white);
  margin: 0px auto 5px;
  max-width: 40%;
  display: none;
}

.about .showcase-content .resume .sub-2 {
  margin: 30px 0;
  display: none;
}

.about .showcase-content .resume .container ul li .line {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  line-height: 40px;
  text-align: center;
  z-index: 1;
  border-radius: 50%;
  color: white;
  background-color: var(--primary-color);
}

.about .showcase-content .resume .container ul li .line.edu .reactIcon {
  font-size: 22px;
  margin-top: -3px;
}

.about .showcase-content .resume .container ul li .line.exp .reactIcon {
  font-size: 18px;
  margin-top: -3px;
}

.about .showcase-content .resume .container ul li .release-date {
  color: var(--light-color);
  font-size: 12px;
  padding: 1px 10px;
  display: inline-block;
  margin-bottom: 12px;
  border-radius: 20px;
  font-weight: 600;
  background-color: var(--light-black);
  opacity: 0.8;
  margin-top: 10px;
}

.about .showcase-content .resume .container ul li .duration {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}

.about .showcase-content .resume .container ul li .duration .type {
  text-decoration: underline;
}

.about .showcase-content .resume .container ul li img {
  width: 250px;
  margin-top: 20px;
}

.about .showcase-content .resume .education {
  position: relative;
  width: 100%;
  padding-right: 8px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.about .showcase-content .resume .education .container ul li .degree {
  font-size: 18px;
  margin: 7px 0 10px;
}

.about .showcase-content .resume .education .container ul li .place {
  font-size: 15px;
}

.about .showcase-content .resume .experience {
  position: relative;
  width: 100%;
  padding-left: 8px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.about .showcase-content .resume .experience .container ul li .post {
  font-size: 18px;
  margin: 7px 0 10px;
}

.about .showcase-content .resume .experience .container ul li .post .place {
  opacity: 0.8;
  font-weight: 600;
  font-size: 15px;
  position: relative;
  padding-left: 26px;
}

.about
  .showcase-content
  .resume
  .experience
  .container
  ul
  li:hover
  .post
  .place {
  color: var(--primary-color);
  opacity: 1;
}

.about
  .showcase-content
  .resume
  .experience
  .container
  ul
  li
  .post
  .place::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  background-color: var(--light-black);
  left: 7px;
  top: 9px;
  opacity: 0.8;
}

.about .showcase-content .resume .experience .container ul li .description {
  margin: 0;
  font-size: 14px;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 800px) {
  .about .showcase-content .resume .title {
    margin-bottom: 30px;
  }

  .about .showcase-content .resume .education {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .about .showcase-content .resume .experience {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }

  .about .showcase-content .resume .title {
    display: none;
  }

  .about .showcase-content .resume .sub-1 {
    display: block;
  }

  .about .showcase-content .resume .sub-2 {
    display: block;
  }

  hr.sub-separator {
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  .about .showcase-content .resume .container ul li .release-date {
    margin-left: -10px;
  }

  .about .showcase-content .resume .container ul li img {
    width: 200px;
    margin-left: -10px;
  }

  .about .showcase-content .resume .experience .container ul li .post {
    margin-left: -10px;
    font-size: 17px;
  }

  .about .showcase-content .resume .experience .container ul li .description {
    margin-left: -10px;
    font-size: 14px;
  }

  .about .showcase-content .resume .education .container ul li .degree {
    margin-left: -10px;
    font-size: 17px;
  }

  .about .showcase-content .resume .education .container ul li .place {
    margin-left: -10px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 350px) {
  .about .showcase-content .resume .container ul li .duration {
    margin-left: -10px;
    display: block;
  }

  .about .showcase-content .resume .container ul li img {
    width: 150px;
  }
}

@media only screen and (max-width: 300px) {
  .about .showcase-content .resume .container ul li img {
    width: 120px;
  }
}

@media only screen and (max-width: 250px) {
  .about .showcase-content .resume .container ul li img {
    width: 100px;
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Hobbies Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.about .showcase-content .hobbies .title {
  margin-bottom: 20px;
  text-align: center;
}

.about .showcase-content .hobbies .container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 3rem;
  padding: 1rem 0;
}

.about .showcase-content .hobbies .container .box {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.about .showcase-content .hobbies .container .box .image {
  display: block;
  width: 120px;
}

.about .showcase-content .hobbies .container .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: var(--light-color);
}

.about .showcase-content .hobbies .container .box:hover .overlay {
  opacity: 1;
}

.about .showcase-content .hobbies .container .overlay .text {
  color: var(--primary-color);
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 1150px) {
  .about .showcase-content .hobbies .container {
    grid-gap: 2rem;
  }
}

@media only screen and (max-width: 1050px) {
  .about .showcase-content .hobbies .container {
    grid-gap: 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  .about .showcase-content .hobbies .container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .about .showcase-content .hobbies .title {
    margin-bottom: 15px;
  }

  .about .showcase-content .hobbies .container {
    grid-template-columns: repeat(3, 1fr);
  }

  .about .showcase-content .hobbies .container .box .image {
    width: 100px;
  }

  .about .showcase-content .hobbies .container .overlay .text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 500px) {
  .about .showcase-content .hobbies .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 350px) {
  .about .showcase-content .hobbies .container {
    grid-template-columns: repeat(1, 1fr);
  }
}
