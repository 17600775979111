@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ Not Found Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.notFound {
  position: relative;
  left: 50%;
  margin-left: -463px;
  display: inline-block;
  font-family: Nunito, sans-serif;
}

.notFound .smile {
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
}

.notFound .smile img {
  width: 270px;
  z-index: 20;
}

.notFound .errorInfos {
  display: inline-block;
}

.notFound .errorInfos h1 {
  font-size: 200px;
  text-transform: uppercase;
  user-select: text;
}

.notFound .errorInfos h2 {
  font-size: 40px;
  user-select: text;
}

.notFound .errorInfos p {
  font-size: 22px;
  font-weight: 600;
  opacity: 0.8;
  user-select: text;
  margin-bottom: 0;
}

.notFound .errorInfos .backBtn {
  margin-top: 10px;
  padding-bottom: 6px;
}

.notFound .errorInfos .backBtn button {
  padding: 8px 24px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px var(--btn-shadow-light);
}

.notFound .errorInfos .backBtn button:hover {
  background-color: #448fff;
}

.notFound .errorInfos .backBtn button:active {
  box-shadow: 0 2px var(--btn-shadow-dark);
  transform: translateY(2px);
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 1500px) {
  .notFound {
    margin-left: -350px;
  }

  .notFound .smile img {
    width: 200px;
  }

  .notFound .errorInfos h1 {
    font-size: 150px;
  }

  .notFound .errorInfos h2 {
    font-size: 30px;
  }

  .notFound .errorInfos p {
    font-size: 17px;
  }
}

@media only screen and (max-width: 1000px) {
  .notFound {
    margin-left: -304px;
  }

  .notFound .smile {
    margin-right: 20px;
  }

  .notFound .smile img {
    width: 190px;
  }

  .notFound .errorInfos h1 {
    font-size: 120px;
  }

  .notFound .errorInfos h2 {
    font-size: 25px;
  }

  .notFound .errorInfos p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .notFound {
    margin-left: -261px;
  }

  .notFound .smile img {
    width: 150px;
  }

  .notFound .errorInfos h1 {
    font-size: 112px;
  }

  .notFound .errorInfos h2 {
    font-size: 25px;
  }

  .notFound .errorInfos p {
    font-size: 13px;
  }

  .notFound .errorInfos .backBtn button {
    padding: 8px 22px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .notFound {
    margin-left: -185px;
  }

  .notFound .smile {
    display: block;
    margin-right: 0;
    text-align: center;
  }

  .notFound .smile img {
    width: 180px;
  }

  .notFound .errorInfos {
    display: block;
  }

  .notFound .errorInfos h1 {
    text-align: center;
    font-size: 90px;
  }

  .notFound .errorInfos h2 {
    text-align: center;
    font-size: 22px;
  }

  .notFound .errorInfos p {
    font-size: 14px;
    text-align: center;
  }

  .notFound .errorInfos .backBtn {
    margin-top: 8px;
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .notFound {
    margin-left: -173px;
  }

  .notFound .smile img {
    width: 160px;
  }

  .notFound .errorInfos h1 {
    font-size: 70px;
  }

  .notFound .errorInfos h2 {
    font-size: 18px;
  }

  .notFound .errorInfos p {
    font-size: 13px;
  }

  .notFound .errorInfos .backBtn {
    margin-top: 6px;
  }

  .notFound .errorInfos .backBtn button {
    padding: 6px 20px;
    font-size: 12px;
    box-shadow: 0 3px var(--btn-shadow-light);
  }

  .notFound .errorInfos .backBtn button:active {
    box-shadow: 0 2px var(--btn-shadow-dark);
    transform: translateY(1px);
  }
}

@media only screen and (max-width: 400px) {
  .notFound {
    margin-left: -133px;
  }

  .notFound .smile img {
    width: 140px;
  }

  .notFound .errorInfos h1 {
    font-size: 50px;
  }

  .notFound .errorInfos h2 {
    font-size: 15px;
  }

  .notFound .errorInfos p {
    font-size: 10px;
  }

  .notFound .errorInfos .backBtn button {
    padding: 5px 18px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 300px) {
  .notFound {
    margin-left: -107px;
  }

  .notFound .smile img {
    width: 120px;
  }

  .notFound .errorInfos h1 {
    font-size: 40px;
  }

  .notFound .errorInfos h2 {
    font-size: 12px;
  }

  .notFound .errorInfos p {
    font-size: 8px;
  }

  .notFound .errorInfos .backBtn button {
    padding: 5px 16px;
  }
}
