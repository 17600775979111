/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Separator ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

hr.portfolioSeparator {
  border-top: 1px solid var(--dark-white);
  margin: 35px auto 40px;
  max-width: 40%;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 800px) {
  hr.portfolioSeparator {
    margin: 35px auto 35px;
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Projects Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.portfolio .showcase-content .projects .title {
  text-align: center;
  margin-bottom: 40px;
}

.portfolio .showcase-content .projects .container {
  position: relative;
}

.portfolio .showcase-content .projects .container .fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.portfolio .showcase-content .projects .container .demo {
  width: 100%;
  display: flex; /* Set the default display to flex */
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-inline: 60px;
}

.portfolio .showcase-content .projects .container .demo.hidden {
  display: none;
}

.portfolio .showcase-content .projects .container .demo.selected {
  display: flex;
}

.portfolio .showcase-content .projects .container .demo .thumbnail {
  position: relative;
  width: 100%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin: auto;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.portfolio .showcase-content .projects .container .demo .thumbnail img {
  width: 100%;
  box-shadow: 1px 1px 5px var(--dark-white);
  vertical-align: middle;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.portfolio .showcase-content .projects .container .demo .thumbnail:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.portfolio .showcase-content .projects .container .demo .introduction {
  position: relative;
  width: 100%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin: auto;
  padding-left: 4%;
  padding-right: 2%;
}

.portfolio .showcase-content .projects .container .demo .introduction h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--primary-color);
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .category {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
  color: var(--dark-gray);
  position: relative;
  margin-left: 8px;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .description {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.portfolio .showcase-content .projects .container .demo .introduction .version {
  margin-bottom: 10px;
  display: inline-block;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .version
  select {
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  outline: none;
  padding: 5px 25px 5px 5px;
  border: 1px solid var(--dark-white);
  -webkit-box-shadow: 0 0 5px var(--dark-white);
  box-shadow: 0px 0px 5px var(--dark-white);
  color: var(--dark-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .version
  select {
  font-size: 15px;
}

@media (hover: none) {
  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    select:active {
    background-color: var(--dark-white);
  }
}

@media (hover: hover) {
  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    select:hover {
    background-color: var(--dark-white);
  }
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .version
  select
  option {
  background-color: var(--dark-white);
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .version
  .reactIcon {
  position: relative;
  left: -18px;
  pointer-events: none;
  font-size: 16px;
  margin-top: -1px;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .key-words {
  font-size: 15px;
  font-weight: 400;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .key-words
  > span {
  background-color: var(--dark-gray);
  color: var(--light-color);
  margin-inline: 2.5px;
  padding: 4px 8px;
  border-radius: 10px;
  opacity: 0.7;
  display: inline-block;
  margin-bottom: 6px;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn {
  margin-top: 16px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  width: 10rem;
  height: auto;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn#simple-redirect {
  cursor: not-allowed;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn#simple-redirect:active {
  transform: none;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn
  .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background-color: var(--light-black);
  border-radius: 1.625rem;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn
  .circle
  .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn
  .circle
  .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn
  .circle
  .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid var(--light-color);
  border-right: 0.125rem solid var(--light-color);
  transform: rotate(45deg);
  margin-top: -1px;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn
  .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: var(--dark-color);
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn:hover
  .circle {
  width: 100%;
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn:hover
  .circle
  .icon.arrow {
  background-color: var(--light-color);
  transform: translate(1rem, 0);
}

.portfolio
  .showcase-content
  .projects
  .container
  .demo
  .introduction
  .demo-btn:hover
  .button-text {
  color: var(--light-color);
}

@media (hover: hover) {
  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn:active {
    transform: scale(0.9);
  }
}

.portfolio .showcase-content .projects .container .prev,
.portfolio .showcase-content .projects .container .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -65px;
  width: auto;
  padding: 10px 14px;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 30px;
  transition: 0.6s ease;
}

.portfolio .showcase-content .projects .container .prev {
  left: 0;
  border-radius: 0 10px 10px 0;
}

.portfolio .showcase-content .projects .container .next {
  right: 0;
  border-radius: 10px 0 0 10px;
}

@media (hover: none) {
  .portfolio .showcase-content .projects .container .prev:active,
  .portfolio .showcase-content .projects .container .next:active {
    background-color: var(--dark-white);
    color: var(--primary-color);
  }
}

@media (hover: hover) {
  .portfolio .showcase-content .projects .container .prev:hover,
  .portfolio .showcase-content .projects .container .next:hover {
    background-color: var(--dark-white);
    color: var(--primary-color);
  }
}

.portfolio .showcase-content .projects .container .prev:active,
.portfolio .showcase-content .projects .container .next:active {
  transition: 0s;
  opacity: 0.8;
}

.portfolio .showcase-content .projects .container .bullets-indicators {
  text-align: center;
}

.portfolio .showcase-content .projects .container .bullets-indicators .dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 4px;
  background-color: var(--dark-white);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

@media (hover: hover) {
  .portfolio
    .showcase-content
    .projects
    .container
    .bullets-indicators
    .dot:hover {
    background-color: var(--dark-gray);
  }
}

.portfolio
  .showcase-content
  .projects
  .container
  .bullets-indicators
  .dot.active {
  background-color: var(--primary-color);
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 1500px) {
  .portfolio .showcase-content .projects .container .demo {
    padding-inline: 50px;
  }

  .portfolio .showcase-content .projects .container .demo .introduction h3 {
    font-size: 23px;
    margin-bottom: 3px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .category {
    font-size: 15px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .description {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .key-words {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1300px) {
  .portfolio .showcase-content .projects .container .demo {
    padding-inline: 45px;
  }

  .portfolio .showcase-content .projects .container .demo .thumbnail {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .portfolio .showcase-content .projects .container .demo .introduction {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 4%;
    padding-right: 1%;
  }

  .portfolio .showcase-content .projects .container .demo .introduction h3 {
    font-size: 22px;
    margin-bottom: 2px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .description {
    font-size: 15px;
    margin-bottom: 12px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version {
    margin-bottom: 7px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    select {
    font-size: 13px;
    padding: 5px 20px 5px 5px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    .reactIcon {
    margin-top: 1px;
    left: -17px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .key-words {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn {
    margin-top: 10px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle {
    width: 2.6rem;
    height: 2.6rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle
    .icon.arrow {
    left: 0.5rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle
    .icon.arrow::before {
    width: 0.525rem;
    height: 0.525rem;
    margin-top: 0px;
    margin-right: -1px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .button-text {
    line-height: 1.3;
    font-size: 16px;
    margin: 0 0 0 1rem;
  }

  .portfolio .showcase-content .projects .container .prev,
  .portfolio .showcase-content .projects .container .next {
    margin-top: -52px;
    padding: 8px 10px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 1130px) {
  .portfolio .showcase-content .projects .container .demo {
    padding-inline: 60px;
  }

  .portfolio .showcase-content .projects .container .demo .thumbnail {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .portfolio .showcase-content .projects .container .demo .introduction {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 4%;
    text-align: center;
  }

  .portfolio .showcase-content .projects .container .demo .introduction h3 {
    font-size: 25px;
    margin-bottom: 5px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .description {
    font-size: 18px;
    margin-bottom: 13px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version {
    margin-bottom: 10px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    select {
    font-size: 15px;
    padding: 5px 25px 5px 5px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    .reactIcon {
    margin-top: -1px;
    left: -18px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .key-words {
    font-size: 15px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn {
    margin-top: 16px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle {
    width: 3rem;
    height: 3rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle
    .icon.arrow {
    left: 0.625rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle
    .icon.arrow::before {
    width: 0.625rem;
    height: 0.625rem;
    margin-top: -1px;
    margin-right: 0px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .button-text {
    line-height: 1.5;
    font-size: 16px;
    margin: 0 0 0 2rem;
  }

  .portfolio .showcase-content .projects .container .prev,
  .portfolio .showcase-content .projects .container .next {
    margin-top: -55px;
    padding: 10px 14px;
    font-size: 30px;
    top: 23vw;
  }
}

@media only screen and (max-width: 800px) {
  .portfolio .showcase-content .projects .title {
    margin-bottom: 20px;
  }

  .portfolio .showcase-content .projects .container .demo {
    padding-inline: 45px;
  }

  .portfolio .showcase-content .projects .container .demo .introduction h3 {
    font-size: 22px;
    margin-bottom: 5px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .category {
    font-size: 14px;
    margin-left: 7px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .description {
    font-size: 15px;
    margin-bottom: 13px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version {
    margin-bottom: 10px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    select {
    font-size: 13px;
    padding: 5px 20px 5px 5px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    .reactIcon {
    margin-top: 1px;
    left: -17px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .key-words {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn {
    margin-top: 10px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle {
    width: 2.5rem;
    height: 2.5rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle
    .icon.arrow {
    left: 0.4rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle
    .icon.arrow::before {
    width: 0.525rem;
    height: 0.525rem;
    margin-top: 0px;
    margin-right: -1px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .button-text {
    line-height: 1.2;
    font-size: 14px;
    margin: 0 0 0 0.4rem;
  }

  .portfolio .showcase-content .projects .container .prev,
  .portfolio .showcase-content .projects .container .next {
    margin-top: -40px;
    padding: 8px 10px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 530px) {
  .portfolio .showcase-content .projects .container .demo {
    padding-inline: 32px;
  }

  .portfolio .showcase-content .projects .container .demo .introduction h3 {
    font-size: 18px;
    margin-bottom: 3px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .category {
    font-size: 11px;
    margin-left: 6px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .description {
    font-size: 12px;
    margin-bottom: 11px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version {
    margin-bottom: 7px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    select {
    font-size: 10px;
    padding: 3px 17px 3px 3px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .version
    .reactIcon {
    margin-top: 3px;
    left: -15px;
    font-size: 14px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .key-words {
    font-size: 10px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn {
    margin-top: 6px;
    width: 7rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle {
    width: 1.8rem;
    height: 1.8rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle
    .icon.arrow {
    left: 0.05rem;
    width: 1rem;
    height: 0.06rem;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .circle
    .icon.arrow::before {
    width: 0.35rem;
    height: 0.35rem;
    border-top: 0.1rem solid var(--light-color);
    border-right: 0.1rem solid var(--light-color);
    margin-top: 1px;
  }

  .portfolio
    .showcase-content
    .projects
    .container
    .demo
    .introduction
    .demo-btn
    .button-text {
    line-height: 0.4;
    font-size: 13px;
    padding: 0.75rem 0;
    margin: 0 0 0 1.7rem;
  }

  .portfolio .showcase-content .projects .container .prev,
  .portfolio .showcase-content .projects .container .next {
    margin-top: -40px;
    padding: 5px 7px;
    font-size: 20px;
    top: 26vw;
  }
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Achievements Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.portfolio .showcase-content .certificates .title {
  text-align: center;
  margin-bottom: 25px;
}

.portfolio .showcase-content .certificates .container {
  display: table;
  margin: 0 auto;
}

.portfolio .showcase-content .certificates .container .certificate {
  margin: 15px 0;
}

.portfolio .showcase-content .certificates .container .icon {
  width: 30px;
  vertical-align: middle;
  position: relative;
}

.portfolio .showcase-content .certificates .container .date {
  position: relative;
  font-weight: 500;
  opacity: 0.8;
  padding-left: 10px;
  font-size: 16px;
  display: inline;
}

.portfolio .showcase-content .certificates .container .name {
  position: relative;
  font-weight: 500;
  padding-left: 26px;
  text-transform: uppercase;
  font-size: 16px;
}

.portfolio .showcase-content .certificates .container .date::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  background-color: var(--light-black);
  right: -17px;
  top: 10px;
  opacity: 0.8;
}

.portfolio
  .showcase-content
  .certificates
  .container
  .certificate:hover
  > .date {
  color: var(--primary-color);
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 800px) {
  .portfolio .showcase-content .certificates .title {
    margin-bottom: 15px;
  }

  .portfolio .showcase-content .certificates .container {
    text-align: center;
  }

  .portfolio .showcase-content .certificates .container .icon {
    width: 28px;
  }

  .portfolio .showcase-content .certificates .container .name {
    padding-left: 0px;
  }

  .portfolio .showcase-content .certificates .container .date {
    padding-left: 0;
    display: block;
    font-size: 15px;
  }

  .portfolio .showcase-content .certificates .container .date::after {
    display: none;
  }

  .portfolio .showcase-content .certificates .container .name {
    font-size: 14px;
  }
}

@media only screen and (max-width: 530px) {
  .portfolio .showcase-content .certificates .container .icon {
    width: 25px;
  }

  .portfolio .showcase-content .certificates .container .date {
    font-size: 13px;
  }

  .portfolio .showcase-content .certificates .container .name {
    font-size: 13px;
  }
}

@media only screen and (max-width: 300px) {
  .portfolio .showcase-content .certificates .container .icon {
    width: 20px;
  }

  .portfolio .showcase-content .certificates .container .date {
    font-size: 12px;
  }

  .portfolio .showcase-content .certificates .container .name {
    font-size: 12px;
  }
}
