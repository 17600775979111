/* %%%%%%%%%%%%%%%%%%%%%%%%%%%[ Home Content ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.home .showcase-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home .showcase-content .presentation {
  position: relative;
  flex-basis: 60%;
}

.home .showcase-content .presentation .shining-text {
  margin-bottom: 10px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    var(--light-gray),
    var(--dark-gray),
    var(--light-gray)
  );
  background-repeat: no-repeat;
  background-size: 80%;
  width: 130px;
  animation: shine 2s linear infinite;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  font-size: x-large;
}

@-webkit-keyframes shine {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}

@keyframes shine {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}

.home .showcase-content .presentation h1 {
  font-size: 60px;
  letter-spacing: 2px;
  margin: 5px 0;
  font-weight: 600;
}

.home .showcase-content .presentation h1 .myName {
  color: var(--primary-color);
  filter: drop-shadow(0px 0px 2px var(--primary-color));
  background: none;
}

/* .home .showcase-content .presentation h1 .myName::-webkit-selection {
  color: var(--red-color);
  background: #ffd700;
}

.home .showcase-content .presentation h1 .myName::-ms-selection {
  color: var(--red-color);
  background: #ffd700;
}

.home .showcase-content .presentation h1 .myName::-moz-selection {
  color: var(--red-color);
  background: #ffd700;
}

.home .showcase-content .presentation h1 .myName::selection {
  color: var(--red-color);
  background: #ffd700;
} */

.home .showcase-content .presentation .workplace {
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 10px;
  opacity: 0.6;
}

.home .showcase-content .presentation p {
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 23px;
  overflow: hidden;
  text-align: justify;
}

.home .showcase-content .presentation .typewriter-container {
  margin-top: 7px;
}

.home
  .showcase-content
  .presentation
  .typewriter-container
  p
  span:last-of-type {
  background-color: var(--primary-color);
  color: transparent !important;
  width: 3px;
  display: inline-block;
}

.home .showcase-content .presentation .cv-btn {
  color: white;
  background-color: var(--primary-color);
  padding: 15px 50px;
  border-radius: 15px;
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 23px;
  box-shadow: 0 2px 10px #003870;
  border: 5px solid var(--primary-color);
  overflow: hidden;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  outline: none;
  line-height: 25px;
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
}

.home .showcase-content .presentation .cv-btn .reactIcon {
  margin-left: 7px;
  font-size: 25px;
  margin-top: -3px;
}

.home .showcase-content .presentation .cv-btn:hover {
  background-color: #0066ff;
  border: 5px solid var(--primary-color);
}

.home .showcase-content .presentation .cv-btn:active {
  background-color: #4d94fd;
  border: 5px solid var(--primary-color);
}

span.ripple {
  background-color: #4d93fd80;
  border-radius: 50%;
  position: absolute;
  transform: scale(0);
  animation: ripple 0.5s linear forwards;
}

@-webkit-keyframes ripple {
  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes ripple {
  to {
    transform: scale(1);
    opacity: 0;
  }
}

.sub-line {
  display: none;
}

.home .showcase-content .presentation .lang-menu {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: -4px;
}

.home .showcase-content .presentation .lang-menu .selected-lang {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 2;
  border: 2px solid var(--dark-white);
  padding: 5px 12px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 15px;
  width: 120px;
}

.home .showcase-content .presentation .lang-menu ul {
  margin: 0;
  padding: 0;
  background-color: var(--dark-white);
  border-radius: 5px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
}

.home .showcase-content .presentation .lang-menu ul li {
  list-style: none;
}

.home .showcase-content .presentation .lang-menu ul li > span {
  display: block;
  padding: 5px 12px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  line-height: 2;
}

.home .showcase-content .presentation .lang-menu ul li > span:hover {
  background-color: var(--light-black);
  width: 100%;
  border-radius: 5px;
  color: var(--light-color);
}

.home .showcase-content .presentation .lang-menu .flag-icon {
  margin-right: 7px;
  width: 30px;
  height: 30px;
}

.home .showcase-content .photo-container {
  position: relative;
  flex-basis: 40%;
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.home .showcase-content .photo-container .picture {
  width: 100%;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%[ Responsive ]%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

@media only screen and (max-width: 1500px) {
  .home .showcase-content .presentation .shining-text {
    font-size: 20px;
  }

  .home .showcase-content .presentation h1 {
    font-size: 57px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 42px;
  }

  .home .showcase-content .presentation p {
    font-size: 21px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 22px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1400px) {
  .home .showcase-content .presentation .shining-text {
    font-size: 18px;
  }

  .home .showcase-content .presentation h1 {
    font-size: 53px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 39px;
  }

  .home .showcase-content .presentation p {
    font-size: 18px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 20px;
    padding: 15px 40px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1280px) {
  .home .showcase-content .presentation h1 {
    font-size: 49px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 36px;
  }

  .home .showcase-content .presentation p {
    font-size: 17px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 19px;
    padding: 15px 30px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 21px;
  }
}

@media only screen and (max-width: 1190px) {
  .home .showcase-content .presentation h1 {
    font-size: 45px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 33px;
  }

  .home .showcase-content .presentation p {
    font-size: 16px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 18px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1050px) {
  .home .showcase-content .presentation h1 {
    font-size: 40px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 30px;
  }

  .home .showcase-content .presentation p {
    font-size: 15px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 18px;
    padding: 12px 23px;
    letter-spacing: 2px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .home .showcase-content .presentation h1 {
    font-size: 35px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 27px;
  }

  .home .showcase-content .presentation p {
    font-size: 14px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 16px;
    padding: 12px 20px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 18px;
  }
}

@media only screen and (max-width: 920px) {
  .home .showcase-content .presentation h1 {
    font-size: 31px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 24px;
  }

  .home .showcase-content .presentation p {
    font-size: 13px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 18px;
  }
}

@media only screen and (max-width: 850px) {
  .home .showcase-content .presentation .shining-text {
    font-size: 16px;
  }

  .home .showcase-content .presentation h1 {
    font-size: 29px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 22px;
  }

  .home .showcase-content .presentation p {
    font-size: 12px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 14px;
    padding: 10px 16px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 15px;
  }

  .home .showcase-content .presentation .lang-menu {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 800px) {
  .home .showcase-content {
    flex-direction: column-reverse;
    text-align: center;
  }

  .home .showcase-content .presentation .shining-text {
    margin-left: auto;
    margin-right: auto;
    font-size: x-large;
  }

  .home .showcase-content .presentation h1 {
    font-size: 52px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 40px;
  }

  .home .showcase-content .presentation p {
    font-size: 19px;
  text-align: center;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 22px;
    padding: 20px 50px;
    margin-top: 20px;
    margin-bottom: 4px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 22px;
  }

  .sub-line {
    display: block;
  }

  .home .showcase-content .presentation .lang-menu {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .home .showcase-content .photo-container {
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .home .showcase-content .photo-container .picture {
    width: 70%;
    display: block;
    margin: auto;
  }
}

@media only screen and (max-width: 700px) {
  .home .showcase-content .presentation .shining-text {
    font-size: large;
  }

  .home .showcase-content .presentation h1 {
    font-size: 45px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 35px;
  }

  .home .showcase-content .presentation p {
    font-size: 17px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 20px;
    padding: 19px 40px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .home .showcase-content .presentation h1 {
    font-size: 40px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 30px;
  }

  .home .showcase-content .presentation p {
    font-size: 15px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 18px;
    padding: 16px 34px;
    letter-spacing: 2px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 19px;
  }
}

@media only screen and (max-width: 550px) {
  .home .showcase-content .presentation h1 {
    font-size: 36px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 28px;
  }

  .home .showcase-content .presentation p {
    font-size: 14px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 17px;
    padding: 14px 30px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 18px;
  }
}

@media only screen and (max-width: 500px) {
  .home .showcase-content .presentation h1 {
    font-size: 32px;
  }

  .home .showcase-content .presentation h1 .myName {
    filter: drop-shadow(0px 0px 2px var(--primary-color));
  }

  .home .showcase-content .presentation .workplace {
    font-size: 25px;
  }

  .home .showcase-content .presentation p {
    font-size: 13px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 17px;
    padding: 12px 24px;
    margin-bottom: 3px;
    font-weight: 500;
    letter-spacing: 1.5px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 18px;
  }

  .home .showcase-content .photo-container .picture {
    width: 80%;
  }
}

@media only screen and (max-width: 450px) {
  .home .showcase-content .presentation .shining-text {
    font-size: medium;
  }

  .home .showcase-content .presentation h1 {
    font-size: 29px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 22px;
  }

  .home .showcase-content .presentation p {
    font-size: 13px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 16px;
    padding: 11px 22px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 17px;
  }
}

@media only screen and (max-width: 400px) {
  .home .showcase-content .presentation h1 {
    font-size: 25px;
  }

  .home .showcase-content .presentation .workplace {
    font-size: 20px;
  }

  .home .showcase-content .presentation p {
    font-size: 12.5px;
  }

  .home .showcase-content .presentation .lang-menu {
    margin-bottom: 3px;
  }

  .home .showcase-content .presentation .lang-menu .selected-lang {
    line-height: 1.6;
    padding: 3px 8px;
    font-weight: 400;
    font-size: 12px;
    width: 90px;
  }

  .home .showcase-content .presentation .lang-menu ul {
    width: 90px;
  }

  .home .showcase-content .presentation .lang-menu ul li > span {
    padding: 3px 9px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.6;
  }

  .home .showcase-content .presentation .lang-menu .flag-icon {
    margin-right: 7px;
    width: 20px;
    height: 20px;
  }

  .home .showcase-content .photo-container .picture {
    width: 90%;
  }
}

@media only screen and (max-width: 360px) {
  .home .showcase-content .presentation .shining-text {
    font-size: small;
  }

  .home .showcase-content .presentation h1 {
    font-size: 23px;
  }

  .home .showcase-content .presentation h1 .myName {
    filter: drop-shadow(0px 0px 1px var(--primary-color));
  }

  .home .showcase-content .presentation .workplace {
    font-size: 18.5px;
  }

  .home .showcase-content .presentation p {
    font-size: 12px;
  }

  .home .showcase-content .presentation .cv-btn {
    font-size: 14px;
    padding: 11px 22px;
    font-weight: 400;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 15px;
  }
}

@media only screen and (max-width: 330px) {
  .home .showcase-content .presentation .cv-btn {
    font-size: 12px;
    padding: 10px 20px;
  }

  .home .showcase-content .presentation .cv-btn .reactIcon {
    font-size: 13px;
    margin-left: 5px;
  }
}
